import React, { useEffect, useState } from 'react'
import { navigate, graphql } from 'gatsby'
import {
  getAuth,
  getStoredMarket,
  useLocation,
  Loading,
  getOrderAfterPaymentData,
  OrderStatus,
  log,
  CLOrder,
  useAuth,
} from '@ecommerce/shared'
import AfterPayment from '../../templates/afterPayment'
import { PgPageProps } from '../../types/PgPages'
import withPageTransition from '../../components/withPageTransition'
import { stringArrayToElementList, onRedirectBack, PageWrapper } from '../../components/Payment'
import secrets from '../../config/secrets'
import LayoutStaticPage from '../../components/Layout/LayoutStaticPage'
import { sendPurchaseEvent } from '../../utils/events'

const { COUNTRY: country, TAX_MULTIPLIER: taxMultiplier } = secrets

const PaymentPending = (props: PgPageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [metadata, setMetadata] = useState<CLOrder['attributes']['metadata']>()
  const { getEntityInfo } = useAuth()

  const {
    title,
    upperText,
    upperOnDeliveryText,
    image,
    middleTextGuest,
    middleTextUser,
    upperButtonTextUser,
    upperButtonTextGuest,
    middleTextToPayGuest,
    middleTextToPayUser,
    middleTextQr,
    infoTextQr,
    middleTextQrGuest,
  } = props.data.allContentfulPgPage.edges[0].node.template

  const currentCity = getStoredMarket()

  const { isBolivia, toCurrency } = useLocation()
  const [orderNumber, setOrderNumber] = useState<number>(0)
  const isAuth = getAuth()
  const isBO = isBolivia()

  const params = new URLSearchParams(props.location.search)
  const orderId = params.get('req_reference_number') || ''
  const toPay = params.has('toPay')
  const isQR = params.has('isQR')

  const getMiddleText = () => {
    let text = middleTextGuest
    const isBoToPay = isBO && toPay
    const isBoQR = isBO && isQR

    if (isAuth) {
      text = middleTextUser

      if (isBoToPay) text = middleTextToPayUser
      if (isBoQR) text = middleTextQr
    } else if (isBoToPay) {
      text = middleTextToPayGuest
    }

    if (isBoQR && !isAuth) {
      text = middleTextQrGuest
    }

    return text
  }

  const upperTextToDisplay = isBO && toPay ? upperOnDeliveryText : stringArrayToElementList(upperText, orderNumber)
  const upperButtonNavigate = getAuth() ? `/my-orders` : `/?create_account=true`

  const goBack = () => onRedirectBack('/')

  useEffect(() => {
    if (!orderId || !currentCity) {
      goBack()

      return
    }

    const fetchData = async () => {
      try {
        const data = await getOrderAfterPaymentData(orderId, country)

        if (!data) return goBack()

        const { status, number, metadata: orderMetadata } = data
        setOrderNumber(number)
        const isValid = status === OrderStatus.PLACED || status === OrderStatus.APPROVED || isQR || toPay

        if (!isValid) return goBack()
        const entityInfo = getEntityInfo()
        sendPurchaseEvent(orderId, data, entityInfo, isBO)

        setMetadata(orderMetadata)
        setIsLoading(false)
      } catch (error) {
        log.error(error)

        goBack()
      }
    }

    fetchData()
  }, [orderId])

  return (
    <>
      {currentCity && (
        <LayoutStaticPage title="Despacho y Pago">
          <PageWrapper>
            {isLoading ? (
              <Loading className="Loading" />
            ) : (
              <AfterPayment
                orderId={orderId}
                desktopTitle={title}
                upperText={upperTextToDisplay}
                imgSrc={image.file.url}
                imgAlt="truck"
                middleText={stringArrayToElementList(getMiddleText(), orderNumber)}
                upperButtonText={getAuth() ? upperButtonTextUser : upperButtonTextGuest || upperButtonTextUser}
                upperButtonAction={() => navigate(upperButtonNavigate)}
                lowerButtonText="Ir a Inicio"
                lowerButtonAction={() => navigate(`/`)}
                infoText={isQR ? infoTextQr : undefined}
                QRRaw={metadata?.qrCodeRaw}
                QRUrl={metadata?.qrCode}
                hideUpperButton={!isAuth}
              />
            )}
          </PageWrapper>
        </LayoutStaticPage>
      )}
    </>
  )
}

export default withPageTransition(PaymentPending)

export const query = graphql`
  query PaymentSuccessQuery($paymentSuccessId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $paymentSuccessId } }) {
      edges {
        node {
          slug
          title
          contentful_id
          template {
            ... on ContentfulTmAfterPayment {
              id
              image {
                id
                file {
                  url
                }
              }
              upperText
              upperOnDeliveryText
              lowerButtonText
              middleTextUser
              middleTextGuest
              middleTextToPayUser
              middleTextToPayGuest
              title
              upperButtonTextGuest
              upperButtonTextUser
              middleTextQr
              infoTextQr
              upperButtonTextQr
              middleTextQrGuest
            }
          }
        }
      }
    }
  }
`
